import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { NavBar } from "../components/nav-bar";
import { Footer } from "../components/Footer/index";
import ArchImg from "../images/strategy/arch.png";
import EnArchImg from "../images/strategy/en-arch.png";
import ArchImg2 from "../images/strategy/arch2.png";
import Icon1 from "../images/strategy/icon1.svg";
import Icon2 from "../images/strategy/icon2.svg";
import Icon3 from "../images/strategy/icon3.svg";
import Bg from "../images/strategy/bg.png";
import Quo from "../images/quo.png";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default () => {
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <Container bg={Bg}>
        <NavBar main />
        <Wrap>
          <Card>
            <Icon src={Icon1} alt="" />
            <CardItem>
              {i18n.language === "zh" ? <h6 className="title">资产战略</h6> : <h6 className="en-title">Asset Strategy</h6>}
              {i18n.language === "zh" && (
                <p className="content">轻资产企业更受资本市场青睐，企业通过轻资产提高资产效率和回 报，成长性更强，与资本市场联动 的地产金融战略刻不容缓。</p>
              )}
            </CardItem>
          </Card>
          <Card>
            <Icon src={Icon2} alt="" />
            <CardItem>
              {i18n.language === "zh" ? <h6 className="title">资产管理体系</h6> : <h6 className="en-title">Management Consultancy</h6>}
              {i18n.language === "zh" && (
                <p className="content">资产管理是轻资产战略的基础，实现与资本市场紧密结合的轻资产战略，搭建以回报管理和风险控制为中心的管理体系至关重要。</p>
              )}
            </CardItem>
          </Card>
          <Card>
            <Icon src={Icon3} alt="" />
            <CardItem>
              {i18n.language === "zh" ? <h6 className="title">项目回报优化</h6> : <h6 className="en-title">Information System Planning</h6>}
              {i18n.language === "zh" && <p className="content">让资产价值最大化，从资产价值最 大化的角度出发，对项目进行精细 定位、合理定价、方案优化。</p>}
            </CardItem>
          </Card>
        </Wrap>
      </Container>
      <Video>
        {i18n.language === "zh" ? (
          <VideoTitle>
            AMSS <br /> BI ＋ 大数据 ＝ AI 资产管理解决方案
          </VideoTitle>
        ) : (
          <VideoTitle>
            BI ＋ BigData ＝ AI Solution
            <br />
          </VideoTitle>
        )}
        <VideoWrap>
          {i18n.language === "zh" ? <VideoQuo src={Quo} /> : <VideoQuo style={{ top: "-75px" }} src={Quo} />}
          <video width="100%" controls disablePictureInPicture controlslist="nodownload">
            <source src="https://staticfiles-amssdata-com-cn.oss-cn-shanghai.aliyuncs.com/videos/ai-solution.mp4" type="video/mp4" />
          </video>
        </VideoWrap>
      </Video>
      <Arch>
        <Title>
          {" "}
          {i18n.language === "zh" && "1. "} {t("strategytitle1")}
        </Title>
        <img width={960} src={i18n.language === "zh" ? ArchImg : EnArchImg} alt="" />
      </Arch>
      {i18n.language === "zh" && (
        <Main>
          <Title>2. 资产管理体系搭建</Title>
          <img src={ArchImg2} alt="" />
        </Main>
      )}
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Icon = styled.img`
  width: 142px;
  height: 142px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: ${(props) => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const Wrap = styled.div`
  width: 1030px;
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardItem = styled.div`
  width: 276px;
  height: 184px;
  padding: 20px 17px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.7;
  border-radius: 10px;
  background-color: #fff;
  .en-title {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.46px;
    text-align: center;
    color: #2f3852;
  }
  .title {
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.46px;
    text-align: center;
    color: #2f3852;
  }
  .content {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.63;
    color: #2f3852;
  }
`;

const VideoWrap = styled.div`
  width: 728px;
  height: 437px;
  position: relative;
  background-color: #252525;
`;

const Main = styled.div`
  padding-top: 46px;
  padding-bottom: 53px;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
`;
const Video = styled(Main)`
  background-color: #2f3852;
`;

const VideoQuo = styled.img`
  position: absolute;
  width: 66px;
  left: 0;
  top: -110px;
`;

const Arch = styled(Main)`
  background: #dddfe6;
`;

const Title = styled.h2`
  padding-bottom: 30px;
  font-size: 21px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.46px;
  text-align: center;
  color: #2f3852;
`;

const VideoTitle = styled(Title)`
  color: #fff;
`;
